<template>
  <div class="container">
    <div
      class="row u-align-items-center u-justify-content-center u-margin-top u-margin-bottom"
    >
      <div
        v-if="pageStatus === 'started'"
        class="es-xs-12 es-md-6 es-lg-5 o-card"
      >
        <div>
          <h4 class="u-color-success">{{ $t("resetPassword.title") }}</h4>
          <p class="u-color-muted">{{ $t("resetPassword.description") }}</p>
        </div>
        <div class="u-margin-top">
          <BaseInput
            v-model="form.password"
            block
            :minlength="6"
            :mandotary="validationActive"
            :label="$t('resetPassword.password')"
            :placeholder="$t('resetPassword.passwordEnter')"
            type="password"
          ></BaseInput>
          <BaseInput
            v-model="form.rePassword"
            block
            :mandotary="validationActive"
            :label="$t('resetPassword.passwordRepeat')"
            :placeholder="$t('resetPassword.passwordRepeatEnter')"
            type="password"
          ></BaseInput>
        </div>
        <div class="u-margin-top">
          <Button variant="primary" block @click="RESET_PASSWORD" icon="check">
            {{ $t("resetPassword.button") }}
          </Button>
        </div>
        <div class="u-margin-top">
          <p>
            <small>
              <i class="icofont-info"></i>
              {{ $t("resetPassword.description2") }}</small
            >
          </p>
        </div>
        <div class="u-text-align-center u-margin-top">
          <a target="_blank" href="https://rezzervasyon.com">
            <img src="@/assets/images/Logo.png" alt="" />
          </a>
          <p>
            <small
              ><i>{{
                $t("track.footer", { version: new Date().getFullYear() })
              }}</i></small
            >
          </p>
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img width="120" src="@/assets/images/google-play.webp" alt="" />
            </a>
            <a
              target="_blank"
              class="u-margin-left-xsmall-sm-up u-margin-top-md-down u-display-inline-block"
              href="https://rezzervasyon.com"
            >
              <img width="120" src="@/assets/images/app-store.webp" alt="" />
            </a>
          </div>
        </div>
        <div class="u-margin-top">
          <p class="u-color-muted">
            <small class="u-font-size-xsmall">
              <i class="icofont-warning"></i>
              {{ $t("resetPassword.description3") }}</small
            >
          </p>
        </div>
      </div>
      <div
        class="es-xs-12 es-md-6 es-lg-5 o-card"
        v-else-if="pageStatus === 'passwordReset'"
      >
        <div class="u-text-align-center">
          <img src="@/assets/images/confirmed.gif" width="100" alt="" />
          <h3>{{ $t("resetPassword.successTitle") }}</h3>
          <p>
            {{ $t("resetPassword.success", { fullname: form.fullname }) }}
          </p>
        </div>
        <div class="u-text-align-center u-margin-top">
          <h3>🎉🎉</h3>
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img src="@/assets/images/google-play.webp" alt="" />
            </a>
            <a
              target="_blank"
              class="u-margin-left-xsmall-sm-up u-margin-top-md-down u-display-inline-block"
              href="https://rezzervasyon.com"
            >
              <img src="@/assets/images/app-store.webp" alt="" />
            </a>
          </div>
          <div class="u-margin-top">
            <a target="_blank" href="https://rezzervasyon.com">
              <img src="@/assets/images/Logo.png" alt="" />
            </a>
          </div>
          <p>
            <small
              ><i>{{
                $t("track.footer", { version: new Date().getFullYear() })
              }}</i></small
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/Base/Inputs/BaseInput.vue";
import Button from "@/components/Base/Button.vue";
import Requests from "@/services/Requests";

export default {
  components: {
    BaseInput,
    Button,
  },
  data() {
    return {
      validationActive: false,
      form: {
        password: "",
        rePassword: "",
      },
      pageStatus: "started",
    };
  },
  methods: {
    VALIDATE() {
      this.validationActive = true;
      if (this.form.password.length < 6) {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("message.minLength.description", { min: 6 }),
          variant: "danger",
          duration: 4000,
        });
        return false;
      } else if (this.form.password !== this.form.rePassword) {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("resetPassword.passwordsMustBeSame"),
          variant: "danger",
          duration: 4000,
        });
        return false;
      }
      return true;
    },
    async RESET_PASSWORD(e, loading) {
      const valid = this.VALIDATE();
      if (!valid) return;
      loading(true);
      const body = {
        password: this.form.password,
        token: this.$route.query?.token || "",
      };
      const response = await Requests.Auth.ResetPassword(body);
      console.log("burası ? ", response);
      if (response.message === "OK") {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t("resetPassword.success"),
          variant: "success",
          duration: 4000,
        });
        this.pageStatus = "passwordReset";
      } else if (response.message === "NOT_FOUND") {
        console.log("burası ? ", response);

        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t(`resetPassword.${response.message}`),
          variant: "danger",
          duration: 6000,
        });
      } else {
        this.$store.dispatch("SET_ACTION_MODAL", {
          isLoading: false,
          status: true,
          message: this.$t(`message.${response.message}.description`),
          variant: "danger",
          duration: 4000,
        });
      }
      loading(false);
    },
  },
};
</script>

<style></style>
