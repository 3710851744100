<template>
  <div class="c-form-control">
    <label class="c-form-control__label">{{ label }}</label>
    <div class="u-position-relative">
      <input
        :type="showPassword ? 'text' : type"
        class="c-form-control__input"
        :class="{
          'c-form-control__input--block': block,
          'c-form-control__input--icon': icon !== '',
          'c-form-control__input--password': type === 'password',
          'c-form-control__required': mandotary,
        }"
        :id="inputId"
        :value="value"
        @input="(e) => $emit('input', e.target.value)"
        @blur="(e) => (e.target.value = value)"
        @keyup="(e) => $emit('keyup', e)"
        :required="mandotary ? 'true' : 'false'"
        :minlength="minlength"
        :maxlength="maxlength"
        :min="min"
        :max="max"
        :pattern="pattern"
        :placeholder="placeholder"
        :readonly="readonly"
      />
      <i
        @click="(e) => $emit('click:icon', e)"
        class="c-form-control__icon o-form-control__icon"
        :class="`icofont-${icon}`"
      ></i>
      <i
        v-if="type === 'password'"
        @click="showPassword = !showPassword"
        class="c-form-control__password o-form-control__icon"
        :class="`icofont-${showPassword ? 'eye' : 'eye-blocked'}`"
      ></i>
      <div class="c-form-control__error-feedback">
        {{ $t("common.required") }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * The only true button.
 * @displayName Best Button
 */
export default {
  props: {
    /**
     * labeeell
     */
    inputId: {
      default: "",
    },
    label: {
      default: "",
    },
    value: {
      default: "",
    },
    icon: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    minlength: {
      default: null,
    },
    maxlength: {
      default: null,
    },
    min: {
      default: null,
    },
    max: {
      default: null,
    },
    pattern: {
      default: null,
    },
    type: {
      default: "text",
    },
    mandotary: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style></style>
